<template>
  <div class="datetime-selector">
    <i class="el-icon-time" />
    <el-select v-model="dateType"    size="small" class="input" @change="dateTypeChange">
      <el-option
        v-for="item in dateTypes"
        :key="item.value"
        :value="item.value"
        :label="item.name"
      ></el-option>
    </el-select>
    <el-date-picker
      v-model="date"
      size="small"
      type="daterange"
      range-separator="至"
      value-format="yyyy-MM-dd"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="dateChange"
      unlink-panels
      :picker-options="pickerOptions"
      style="width: 250px"
    >
    </el-date-picker>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    options: {
      default: [],
    },
  },
  data() {
    return {
      date: [],
      dateType: "create",
      dateTypes: [{ value: "create", name: "创建时间" }],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近两天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    value: {
      handler(v) {
        this.dateType = v.dateType;
        this.date = v.date;
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler(v) {
        this.dateTypes = v;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dateTypeChange(v) {
      this.$emit("change", {
        dateType: v,
        date: [],
      });
    },
    dateChange(v) {
      if (v) {
        this.$emit("change", {
          dateType: this.dateType,
          date: v,
        });
      } else {
        this.$emit("change", {
          dateType: this.dateType,
          date: [],
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.datetime-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .input {
    width: 120px;
    margin-right: 5px;
  }
}
</style>